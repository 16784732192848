import React, { FC } from 'react';
import { Claim } from 'utils/constants/claimconstants';
import { Role } from 'utils/constants/roleconstants';
import { Right } from 'utils/constants/rightconstants';
import { ClaimModel } from 'hooks/api/types';

export interface Session {
    userId?: string | null;
    userName?: string | null;
    role?: Role | null;
    rights: Right[];
    customerRights: CustomerRight[];
    activeCustomerId?: string | null;
    userType?: UserType | null;
}

export interface CustomerRight{
    customerId: string,
    right: Right
}

export enum UserType {
    Admin = 0,
    Customer = 1,
    Api = 2
}

export interface SessionContextValue {
    session?: Session;
    setSession: (claims: ClaimModel[]) => Session;
    clearSession: () => void;
    hasRight: (right: Right) => boolean;
    hasRole: (role: Role) => boolean;
}

const convertToSession = (claims: ClaimModel[]) => {
    
    function customerRightValues(values : string[] | null | undefined) {
        return values 
        ? values.map(customerRight => {
            return JSON.parse(customerRight) as CustomerRight;
        })
        : null;
    }
    
    function claimValues(claimName: Claim) {
        const claim = claims.find((c) => c.type === claimName);
        return claim
            ? claim.values
            : null;
    }

    function claimFirstValue(claimName: Claim) {
        const values = claimValues(claimName);
        return values
            ? values[0]
            : null;
    }

    const userId = claimFirstValue(Claim.UserId);
    const userName = claimFirstValue(Claim.UserName);
    const role = claimFirstValue(Claim.Roles) as Role;
    const rights = claimValues(Claim.Rights) as Right[];
    const customerRights = customerRightValues(claimValues(Claim.CustomerRights)) as CustomerRight[];
    const activeCustomerId = claimFirstValue(Claim.ActiveCustomerId);
    const userTypeClaim = claimFirstValue(Claim.UserType);
    const userType = userTypeClaim != null ? UserType[userTypeClaim as keyof typeof UserType] : null;

    const newSession: Session = {
        userId,
        userName,
        role,
        rights,
        customerRights,
        activeCustomerId,
        userType
    };

    return newSession;
};

const SessionContext = React.createContext<SessionContextValue>({
    session: undefined,
    setSession: (claims: ClaimModel[]) => {
        return convertToSession(claims);
    },
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    clearSession: () => { },
    hasRight: (right: Right) => false,
    hasRole: (role: Role) => false,
});

interface SessionProviderProps {

}

export const SessionProvider: FC<SessionProviderProps> = (props) => {
    const [session, setSession] = React.useState<Session>();

    const updateSession = React.useCallback((claims: ClaimModel[]) => {
        const newSession = convertToSession(claims);
        setSession(newSession);

        return newSession;
    }, [setSession]);

    const clearSession = React.useCallback(() => {
        setSession(undefined);
    }, [setSession]);

    const hasRight = React.useCallback((right: Right) => {
        return session?.rights?.includes(right) ?? false;
    }, [session]);

    const hasRole = React.useCallback((role: Role) => {
        return session?.role === role ?? false;
    }, [session]);

    const context: SessionContextValue = React.useMemo(() => ({
        session,
        setSession: updateSession,
        clearSession,
        hasRight,
        hasRole,
    }), [session, updateSession, clearSession, hasRight, hasRole]);

    return (
        <SessionContext.Provider value={context} {...props} />
    );
};

export const useSession = () => {
    const context = React.useContext(SessionContext);
    if (!context) {
        throw new Error('useSession must be used within a SessionProvider');
    }

    return context;
};
