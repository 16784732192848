import { NavTabProps } from 'components/organisms/NavTabs';
import React, { FC, useEffect } from 'react';
import theme from 'theme';

export const emptyTabs: NavTabProps[] = [];

export interface HeaderContextValue {
    title?: string;
    setTitle: (newTitle: string | undefined) => void;
    tabs?: NavTabProps[];
    setTabs: (newTabs: NavTabProps[]) => void;
    minHeight: number;
    setMinHeight: (newHeight: number) => void;
}

const HeaderContext = React.createContext<HeaderContextValue>({
    title: undefined,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    setTitle: () => { },
    tabs: [],
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    setTabs: () => { },
    minHeight: theme.header.small,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    setMinHeight: () => { },
});

interface HeaderProviderProps {

}

export const HeaderProvider: FC<HeaderProviderProps> = (props) => {
    const [title, setTitle] = React.useState<string>();
    const [tabs, setTabs] = React.useState<NavTabProps[]>([]);
    const [minHeight, setMinHeight] = React.useState<number>(theme.header.small);

    const context: HeaderContextValue = React.useMemo(() => ({
        title,
        setTitle,
        tabs,
        setTabs,
        minHeight,
        setMinHeight
    }), [title, tabs]);

    useEffect(() => {
        document.title = title != null && title !== ''
            ? 'CashOut - ' + title
            : 'CashOut';
    }, [title]);


    return (
        <HeaderContext.Provider value={context} {...props} />
    );
};

export const useHeader = () => {
    const context = React.useContext<HeaderContextValue>(HeaderContext);

    if (!context) {
        throw new Error('useHeader must be used within a HeaderProvider');
    }

    return context;
};

export const useSetHeader = (tabs?: NavTabProps[], title?: string) => {
    const context = React.useContext<HeaderContextValue>(HeaderContext);

    if (!context) {
        throw new Error('useHeader must be used within a HeaderProvider');
    }
    useEffect(() => {
        context.setTitle(title);
    }, [title]);

    useEffect(() => {
        if (tabs != null) {
            context.setTabs(tabs);
        }
    }, [tabs]);

    return context;
};
