export interface Greys {
    [key: number]: string;
}

const greys: Greys = {
    25: '#F7F7F7',
    50: '#EFEFEF',
    100: '#D3D3D3',
    300: '#CCD4DE',
    500: '#B1B7C1',
    700: '#7E838B',
    900: '#9B9696',
};

export default greys;
