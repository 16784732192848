import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import commonNl from './common/nl.json';
import commonEn from './common/en.json';
import languageNl from './language/nl.json';
import languageEn from './language/en.json';
import errorNl from './error/nl.json';
import errorEn from './error/en.json';

//templates
import accountNl from 'components/templates/Account/translations/nl.json';
import accountEn from 'components/templates/Account/translations/en.json';
import customerTemplateNl from 'components/templates/Customer/translations/nl.json';
import customerTemplateEn from 'components/templates/Customer/translations/en.json';

//atoms
import cashoutAmountsNl from 'components/atoms/CashoutAmounts/translations/nl.json';
import cashoutAmountsEn from 'components/atoms/CashoutAmounts/translations/en.json';

//organisms
import balancesTableNl from 'components/organisms/Tables/Balances/translations/nl.json';
import balancesTableEn from 'components/organisms/Tables/Balances/translations/en.json';
import cashoutsTableNl from 'components/organisms/Tables/Cashouts/translations/nl.json';
import cashoutsTableEn from 'components/organisms/Tables/Cashouts/translations/en.json';
import customerUserModificationRequestTableNl from 'components/organisms/Tables/CustomerUserModificationRequest/translations/nl.json';
import customerUserModificationRequestTableEn from 'components/organisms/Tables/CustomerUserModificationRequest/translations/en.json';
import customerLicencePackageTableNl from 'components/organisms/Tables/CustomerLicensePackage/translations/nl.json';
import customerLicencePackageTableEn from 'components/organisms/Tables/CustomerLicensePackage/translations/en.json';
import customerTimeRegistrationTableNl from 'components/organisms/Tables/TimeRegistrations/translations/nl.json';
import customerTimeRegistrationTableEn from 'components/organisms/Tables/TimeRegistrations/translations/en.json';
import customerRequestTableNl from 'components/organisms/Tables/Requests/translations/nl.json';
import customerRequestTableEn from 'components/organisms/Tables/Requests/translations/en.json';

//account pages
import loginNl from 'components/pages/account/Login/translations/nl.json';
import loginEn from 'components/pages/account/Login/translations/en.json';
import forgotNl from 'components/pages/account/ForgotPassword/translations/nl.json';
import forgotEn from 'components/pages/account/ForgotPassword/translations/en.json';
import inviteNl from 'components/pages/account/Invite/translations/nl.json';
import inviteEn from 'components/pages/account/Invite/translations/en.json';
import resetNl from 'components/pages/account/ResetPassword/translations/nl.json';
import resetEn from 'components/pages/account/ResetPassword/translations/en.json';
import registerNl from 'components/pages/account/Register/translations/nl.json';
import registerEn from 'components/pages/account/Register/translations/en.json';
import registerCustomerUserNl from 'components/pages/account/RegisterCustomerUser/translations/nl.json';
import registerCustomerUserEn from 'components/pages/account/RegisterCustomerUser/translations/en.json';
import confirmModificationRequestNl from 'components/pages/account/ConfirmModificationRequest/translations/nl.json';
import confirmModificationRequestEn from 'components/pages/account/ConfirmModificationRequest/translations/en.json';
import confirmCashOutNl from 'components/pages/account/ConfirmCashOut/translations/nl.json';
import confirmCashOutEn from 'components/pages/account/ConfirmCashOut/translations/en.json';
import tfaNl from 'components/pages/account/Tfa/translations/nl.json';
import tfaEn from 'components/pages/account/Tfa/translations/en.json';
import appInformationNl from 'components/pages/account/AppInformation/translations/nl.json';
import appInformationEn from 'components/pages/account/AppInformation/translations/en.json';

//admin pages
import dashboardNl from 'components/pages/admin/dashboard/translations/nl.json';
import dashboardEn from 'components/pages/admin/dashboard/translations/en.json';
import customersListNl from 'components/pages/admin/customer/List/translations/nl.json';
import customersListEn from 'components/pages/admin/customer/List/translations/en.json';
import customersDetailsNl from 'components/pages/admin/customer/Detail/translations/nl.json';
import customersDetailsEn from 'components/pages/admin/customer/Detail/translations/en.json';
import customersOverviewNl from 'components/pages/admin/customer/Overview/translations/nl.json';
import customersOverviewEn from 'components/pages/admin/customer/Overview/translations/en.json';
import customersInformationNl from 'components/pages/admin/customer/Information/translations/nl.json';
import customersInformationEn from 'components/pages/admin/customer/Information/translations/en.json';
import customersFinancialNl from 'components/pages/admin/customer/Financial/translations/nl.json';
import customersFinancialEn from 'components/pages/admin/customer/Financial/translations/en.json';
import customersInvoicesNl from 'components/pages/admin/customer/Invoices/translations/nl.json';
import customersInvoicesEn from 'components/pages/admin/customer/Invoices/translations/en.json';
import customersUsersNl from 'components/pages/admin/customer/Users/translations/nl.json';
import customersUsersEn from 'components/pages/admin/customer/Users/translations/en.json';
import customersUserDetailNl from 'components/pages/admin/customer/UserDetail/translations/nl.json';
import customersUserDetailEn from 'components/pages/admin/customer/UserDetail/translations/en.json';
import customersPlanNl from 'components/pages/admin/customer/Plan/translations/nl.json';
import customersPlanEn from 'components/pages/admin/customer/Plan/translations/en.json';
import customersConnectionsNl from 'components/pages/admin/customer/Connections/translations/nl.json';
import customersConnectionsEn from 'components/pages/admin/customer/Connections/translations/en.json';
import customersConnectorsNl from 'components/pages/admin/customer/Connectors/translations/nl.json';
import customersConnectorsEn from 'components/pages/admin/customer/Connectors/translations/en.json';
import usersNl from 'components/pages/admin/users/Users/translations/nl.json';
import usersEn from 'components/pages/admin/users/Users/translations/en.json';
import resellersNl from 'components/pages/admin/reseller/translations/nl.json';
import resellersEn from 'components/pages/admin/reseller/translations/en.json';

import settingsNl from 'components/pages/admin/settings/Settings/translations/nl.json';
import settingsEn from 'components/pages/admin/settings/Settings/translations/en.json';
import settingsAdminsNl from 'components/pages/admin/settings/Admins/translations/nl.json';
import settingsAdminsEn from 'components/pages/admin/settings/Admins/translations/en.json';
import settingsOverviewNl from 'components/pages/admin/settings/Overview/translations/nl.json';
import settingsOverviewEn from 'components/pages/admin/settings/Overview/translations/en.json';
import settingsRolesNl from 'components/pages/admin/settings/Roles/translations/nl.json';
import settingsRolesEn from 'components/pages/admin/settings/Roles/translations/en.json';
import settingsRightsNl from 'components/pages/admin/settings/Rights/translations/nl.json';
import settingsRightsEn from 'components/pages/admin/settings/Rights/translations/en.json';
import settingsPlansNl from 'components/pages/admin/settings/Plans/translations/nl.json';
import settingsPlansEn from 'components/pages/admin/settings/Plans/translations/en.json';

//customer pages
import customerDashboardNl from 'components/pages/customer/dashboard/translations/nl.json';
import customerDashboardEn from 'components/pages/customer/dashboard/translations/en.json';
import customerSettingsNl from 'components/pages/customer/settings/Detail/translations/nl.json';
import customerSettingsEn from 'components/pages/customer/settings/Detail/translations/en.json';
import customerSettingsInformationNl from 'components/pages/customer/settings/Information/translations/nl.json';
import customerSettingsInformationEn from 'components/pages/customer/settings/Information/translations/en.json';
import customerSettingsProfileNl from 'components/pages/customer/settings/Profile/translations/nl.json';
import customerSettingsProfileEn from 'components/pages/customer/settings/Profile/translations/en.json';
import customerSettingsConnectionsNl from 'components/pages/customer/settings/Connections/translations/nl.json';
import customerSettingsConnectionsEn from 'components/pages/customer/settings/Connections/translations/en.json';
import customerSettingsPayPeriodsNl from 'components/pages/customer/settings/PayPeriods/translations/nl.json';
import customerSettingsPayPeriodsEn from 'components/pages/customer/settings/PayPeriods/translations/en.json';
import customerSettingsRolesNl from 'components/pages/customer/settings/Roles/translations/nl.json';
import customerSettingsRolesEn from 'components/pages/customer/settings/Roles/translations/en.json';
import customerSettingsRoleEditNl from 'components/pages/customer/settings/RoleEdit/translations/nl.json';
import customerSettingsRoleEditEn from 'components/pages/customer/settings/RoleEdit/translations/en.json';
import customerFinancialNl from 'components/pages/customer/financial/Detail/translations/nl.json';
import customerFinancialEn from 'components/pages/customer/financial/Detail/translations/en.json';
import customerFinancialTransactionsNl from 'components/pages/customer/financial/Transactions/translations/nl.json';
import customerFinancialTransactionsEn from 'components/pages/customer/financial/Transactions/translations/en.json';
import customerUsersNl from 'components/pages/customer/users/Users/translations/nl.json';
import customerUsersEn from 'components/pages/customer/users/Users/translations/en.json';
import customerUserOverviewNl from 'components/pages/customer/users/Overview/translations/nl.json';
import customerUserOverviewEn from 'components/pages/customer/users/Overview/translations/en.json';
import customerUserPayPeriodsNl from 'components/pages/customer/users/PayPeriods/translations/nl.json';
import customerUserPayPeriodsEn from 'components/pages/customer/users/PayPeriods/translations/en.json';
import customerUserInformationNl from 'components/pages/customer/users/Information/translations/nl.json';
import customerUserInformationEn from 'components/pages/customer/users/Information/translations/en.json';
import customerTimeRegistrationOverviewNl from 'components/pages/customer/timeRegistration/Overview/translations/nl.json';
import customerTimeRegistrationOverviewEn from 'components/pages/customer/timeRegistration/Overview/translations/en.json';
import customerTimeRegistrationConfirmationNl from 'components/pages/account/TimeRegistrationConfirmation/translations/nl.json';
import customerTimeRegistrationConfirmationEn from 'components/pages/account/TimeRegistrationConfirmation/translations/en.json';
import customerCashoutOverviewNl from 'components/pages/customer/cashout/CashoutOverview/translations/nl.json';
import customerCashoutOverviewEn from 'components/pages/customer/cashout/CashoutOverview/translations/en.json';
import customerPossibilityOverviewNl from 'components/pages/customer/possibility/PossibilityOverview/translations/nl.json';
import customerPossibilityOverviewEn from 'components/pages/customer/possibility/PossibilityOverview/translations/en.json';
import customerRequestOverviewNl from 'components/pages/customer/request/translations/nl.json';
import customerRequestOverviewEn from 'components/pages/customer/request/translations/en.json';

// OAuth pages
import oAuthAuthorizeNl from 'components/pages/oauth/translations/nl.json';
import oAuthAuthorizeEn from 'components/pages/oauth/translations/en.json';

//modals
import useChangeCreditModalNl from 'hooks/modal/useChangeCreditModal/translations/nl.json';
import useChangeCreditModalEn from 'hooks/modal/useChangeCreditModal/translations/en.json';
import useInviteCustomerUserModalNl from 'hooks/modal/useInviteCustomerUserModal/translations/nl.json';
import useInviteCustomerUserModalEn from 'hooks/modal/useInviteCustomerUserModal/translations/en.json';

const resources = {
    'nl-NL': {
        common: commonNl,
        languages: languageNl,
        error: errorNl,

        customerTemplate: customerTemplateNl,

        // Atoms
        cashoutAmounts: cashoutAmountsNl,

        //Organisms
        // Tables
        balancesTable: balancesTableNl,
        cashoutsTable: cashoutsTableNl,
        customerUserModificationRequestTable: customerUserModificationRequestTableNl,
        customerLicencePackageTable: customerLicencePackageTableNl,
        customerTimeRegistrationTable: customerTimeRegistrationTableNl,
        customerRequestTable: customerRequestTableNl,

        //Account
        account: accountNl,
        login: loginNl,
        forgotPassword: forgotNl,
        invite: inviteNl,
        resetPassword: resetNl,
        register: registerNl,
        confirmModificationRequest: confirmModificationRequestNl,
        confirmCashOut: confirmCashOutNl,
        tfa: tfaNl,
        registerCustomerUser: registerCustomerUserNl,
        appInformation: appInformationNl,

        //Customers
        customerDashboard: customerDashboardNl,
        customerFinancial: customerFinancialNl,
        customerFinancialTransactions: customerFinancialTransactionsNl,
        customerSettings: customerSettingsNl,
        customerSettingsInformation: customerSettingsInformationNl,
        customerSettingsProfile: customerSettingsProfileNl,
        customerSettingsConnections: customerSettingsConnectionsNl,
        customerSettingsPayPeriods: customerSettingsPayPeriodsNl,
        customerSettingsRoles: customerSettingsRolesNl,
        customerSettingsRoleEdit: customerSettingsRoleEditNl,
        customerUsers: customerUsersNl,
        customerUserOverview: customerUserOverviewNl,
        customerUserInformation: customerUserInformationNl,
        customerUserPayPeriods: customerUserPayPeriodsNl,
        customerTimeRegistrationOverview: customerTimeRegistrationOverviewNl,
        customerTimeRegistrationConfirmation: customerTimeRegistrationConfirmationNl,
        customerCashoutOverview: customerCashoutOverviewNl,
        customerPossibilityOverview: customerPossibilityOverviewNl,
        customerRequestOverview: customerRequestOverviewNl,

        //Admin
        //Customers
        adminCustomersDashboard: dashboardNl,
        adminCustomersList: customersListNl,
        adminCustomerDetails: customersDetailsNl,
        adminCustomerOverview: customersOverviewNl,
        adminCustomerInformation: customersInformationNl,
        adminCustomerInvoices: customersInvoicesNl,
        adminCustomerUsers: customersUsersNl,
        adminCustomerUserDetail: customersUserDetailNl,
        adminCustomerFinancial: customersFinancialNl,
        adminCustomerPlan: customersPlanNl,
        adminCustomerConnections: customersConnectionsNl,
        adminCustomerConnectors: customersConnectorsNl,

        //Users
        adminUsers: usersNl,

        //Resellers
        adminResellers: resellersNl,

        //Settings
        adminSettings: settingsNl,
        adminSettingsOverview: settingsOverviewNl,
        adminSettingsAdmins: settingsAdminsNl,
        adminSettingsRoles: settingsRolesNl,
        adminSettingsRights: settingsRightsNl,
        adminSettingsPlans: settingsPlansNl,

        oAuthAuthorize: oAuthAuthorizeNl,

        //modals
        useChangeCreditModal: useChangeCreditModalNl,
        useInviteCustomerUserModal: useInviteCustomerUserModalNl
    },
    'en-GB': {
        common: commonEn,
        languages: languageEn,
        error: errorEn,

        customerTemplate: customerTemplateEn,

        // Atoms
        cashoutAmounts: cashoutAmountsEn,

        //Organisms
        //Tables
        balancesTable: balancesTableEn,
        cashoutsTable: cashoutsTableEn,
        customerUserModificationRequestTable: customerUserModificationRequestTableEn,
        customerLicencePackageTable: customerLicencePackageTableEn,
        customerTimeRegistrationTable: customerTimeRegistrationTableEn,
        customerRequestTable: customerRequestTableEn,

        //Account
        account: accountEn,
        login: loginEn,
        forgotPassword: forgotEn,
        invite: inviteEn,
        resetPassword: resetEn,
        register: registerEn,
        confirmModificationRequest: confirmModificationRequestEn,
        confirmCashOut: confirmCashOutEn,
        tfa: tfaEn,
        registerCustomerUser: registerCustomerUserEn,
        appInformation: appInformationEn,

        //Customers
        customerDashboard: customerDashboardEn,
        customerFinancial: customerFinancialEn,
        customerFinancialTransactions: customerFinancialTransactionsEn,
        customerSettings: customerSettingsEn,
        customerSettingsInformation: customerSettingsInformationEn,
        customerSettingsProfile: customerSettingsProfileEn,
        customerSettingsConnections: customerSettingsConnectionsEn,
        customerSettingsPayPeriods: customerSettingsPayPeriodsEn,
        customerSettingsRoles: customerSettingsRolesEn,
        customerSettingsRoleEdit: customerSettingsRoleEditEn,
        customerUsers: customerUsersEn,
        customerUserOverview: customerUserOverviewEn,
        customerUserInformation: customerUserInformationEn,
        customerUserPayPeriods: customerUserPayPeriodsEn,
        customerTimeRegistrationOverview: customerTimeRegistrationOverviewEn,
        customerTimeRegistrationConfirmation: customerTimeRegistrationConfirmationEn,
        customerCashoutOverview: customerCashoutOverviewEn,
        customerPossibilityOverview: customerPossibilityOverviewEn,
        customerRequestOverview: customerRequestOverviewEn,

        //Admin
        //Customers
        adminCustomersDashboard: dashboardEn,
        adminCustomersList: customersListEn,
        adminCustomerDetails: customersDetailsEn,
        adminCustomerOverview: customersOverviewEn,
        adminCustomerInformation: customersInformationEn,
        adminCustomerInvoices: customersInvoicesEn,
        adminCustomerUsers: customersUsersEn,
        adminCustomerUserDetail: customersUserDetailEn,
        adminCustomerFinancial: customersFinancialEn,
        adminCustomerPlan: customersPlanEn,
        adminCustomerConnections: customersConnectionsEn,
        adminCustomerConnectors: customersConnectorsEn,

        //Users
        adminUsers: usersEn,

        //Resellers
        adminResellers: resellersEn,

        //Settings
        adminSettings: settingsEn,
        adminSettingsOverview: settingsOverviewEn,
        adminSettingsAdmins: settingsAdminsEn,
        adminSettingsRoles: settingsRolesEn,
        adminSettingsRights: settingsRightsEn,
        adminSettingsPlans: settingsPlansEn,

        oAuthAuthorize: oAuthAuthorizeEn,

        //modals
        useChangeCreditModal: useChangeCreditModalEn,
        useInviteCustomerUserModal: useInviteCustomerUserModalEn
    }
};

export const languages = ['nl-NL', 'en-GB'] as const;

const ns = Object.keys(resources['nl-NL']);

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources,
        fallbackLng: 'nl-NL',
        load: 'currentOnly',
        supportedLngs: languages,
        debug: process.env.NODE_ENV === 'development',
        ns,
        defaultNS: 'common',
        returnNull: false,
        interpolation: {
            escapeValue: false
        }
    });

export default i18n;
