import { ScreenClass } from 'react-grid-system';

export const gutterWidth = 12;
export const maxScreenClass = 'xxxl' as ScreenClass;

const grid = {
    gutterWidth,
    maxScreenClass
};

export default grid;
