const borderRadius = {
    s: 4,
    m: 8,
    l: 14,
    xl: 18
} as const;

export type BorderRadius = typeof borderRadius;

export default borderRadius;
