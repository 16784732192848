import { Global, css, ThemeProvider } from '@emotion/react';
import { ScreenClassProvider, setConfiguration } from 'react-grid-system';
import { QueryClient, QueryClientProvider } from 'react-query';
import theme from './theme';
import grid from 'theme/grid';
import { SessionProvider } from 'contexts/sessionContext';
import { ModalProvider } from 'react-modal-hook';
import { TransitionGroup } from 'react-transition-group';
import AppRoutes from './AppRoutes';
import 'i18n';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { HeaderProvider } from 'contexts/headerContext';
import { MenuProvider } from 'contexts/menuContext';
import { BrowserRouter } from 'react-router-dom';
import 'react-datepicker/dist/react-datepicker.css';

setConfiguration(grid);

const styles = css`
    @font-face {
        font-family: 'Aeonik Regular';
        src: url('../public/fonts/Aeonik/Aeonik-Regular.woff2') format('woff2'),
            url('../public/fonts/Aeonik/Aeonik-Regular.woff') format('woff'),
            url('../public/fonts/Aeonik/Aeonik-Regular.ttf') format('truetype');
        font-weight: normal;
        font-style: normal;
        font-display: swap;
    }

    @font-face {
        font-family: 'Aeonik Medium';
        src: url('../public/fonts/Aeonik/Aeonik-Medium.woff2') format('woff2'),
            url('../public/fonts/Aeonik/Aeonik-Medium.woff') format('woff'),
            url('../public/fonts/Aeonik/Aeonik-Medium.ttf') format('truetype');
        font-weight: 500;
        font-style: normal;
        font-display: swap;
    }
    body {
        min-height: 100vh;
        overflow: auto;
    }

    #root {
        min-height: 100vh;
    }
    #layers {
        z-index: 100;
    }
    #popper {
        position: absolute;
        top: 0px;
        left: 0px;
        right: 0px;
        z-index: 100;
    }
    * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-family: 'Aeonik', sans-serif;
        &:before, :after {
            box-sizing: border-box;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
        }
    }

    @keyframes rotate {
        from {
            transform: rotate(0);
        }
        to {
            transform: rotate(360deg);
        }
    }

    .icon-wrapper>span {
        display: flex;
    }

    .react-datepicker-wrapper,
    .react-datepicker__input-container,
    .react-datepicker__input-container input {
        display: block;
        width: 100%;
        height: 100%;
    }
    .react-datepicker__header {
        background-color: unset;
        padding: 0 0 8px;
        border: unset;
    }
    .react-datepicker__month-container {
        font-size: 0.75rem;
        font-weight: 500;
    }
    .react-datepicker__month {
        margin: 10px;
    }
    .react-datepicker__day, 
    .react-datepicker__day:hover{
        width: 28px;
        height: 28px;
        border-radius: 14px;
    }
    .react-datepicker__day--keyboard-selected {
        background-color: black;
    }

    .react-datepicker.react-datepicker--time-only {
        border-radius: ${theme.borderRadius.xl}px;
    }
    .react-datepicker__header.react-datepicker__header--time.react-datepicker__header--time--only {
        display: none;
    }
    .react-datepicker__time-container {
        border-radius: ${theme.borderRadius.xl}px;
        overflow: hidden;
    } 
    .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list {
        padding: ${theme.space.inset.xs};
        box-sizing: border-box;
    } 
    .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item {
        height: 2.5rem;
        line-height: 2.5;
        border-radius: ${theme.borderRadius.l}px;
        font-size: 0.75rem;

    }
    .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item.react-datepicker__time-list-item--selected {
        background-color: ${theme.greys[50]};
        color: ${theme.colors.textDark};
        font-weight: normal;
    }

    .react-loading-skeleton {
        z-index: 0!important;
    }

`;

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            retry: false,
            refetchOnWindowFocus: false
        }
    }
});

const App = () => {
    return (
        <>
            <Global styles={styles} />
            <ToastContainer />
            <ThemeProvider theme={theme}>
                <MenuProvider>
                    <HeaderProvider>
                        <SessionProvider>
                            <ScreenClassProvider>
                                <BrowserRouter>
                                    <ModalProvider rootComponent={TransitionGroup}>
                                        <QueryClientProvider client={queryClient}>
                                            <AppRoutes />
                                        </QueryClientProvider>
                                    </ModalProvider>
                                </BrowserRouter>
                            </ScreenClassProvider>
                        </SessionProvider>
                    </HeaderProvider>
                </MenuProvider>
            </ThemeProvider>
        </>
    );
};

export default App;
