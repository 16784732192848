import { lazy, Suspense } from 'react';
import Loading from 'components/atoms/Loading';
import { Routes, Route, Navigate, Outlet } from 'react-router-dom';

// Templates
const Default = lazy(() => import('components/templates/Default'));
const Account = lazy(() => import('components/templates/Account'));
const Customer = lazy(() => import('components/templates/Customer'));
const Admin = lazy(() => import('components/templates/Admin'));

// Account pages
const ForgotPassword = lazy(() => import('components/pages/account/ForgotPassword'));
const Invite = lazy(() => import('components/pages/account/Invite'));
const ResetPassword = lazy(() => import('components/pages/account/ResetPassword'));
const Login = lazy(() => import('components/pages/account/Login'));
const LoginWithUserName = lazy(() => import('components/pages/account/LoginWithUserName'));
const Register = lazy(() => import('components/pages/account/Register'));
const RegisterCustomerUser = lazy(() => import('components/pages/account/RegisterCustomerUser'));
const Tfa = lazy(() => import('components/pages/account/Tfa'));
const ConfirmCashOut = lazy(() => import('components/pages/account/ConfirmCashOut'));
const ConfirmModificationRequest = lazy(() => import('components/pages/account/ConfirmModificationRequest'));
const AppInformation = lazy(() => import('components/pages/account/AppInformation'));

// Admin pages
const AdminDashboard = lazy(() => import('components/pages/admin/dashboard'));
const AdminCustomerList = lazy(() => import('components/pages/admin/customer/List/'));
const AdminCustomerDetail = lazy(() => import('components/pages/admin/customer/Detail'));
const AdminCustomerInvoices = lazy(() => import('components/pages/admin/customer/Invoices'));
const AdminCustomerFinancial = lazy(() => import('components/pages/admin/customer/Financial'));
const AdminCustomerInformation = lazy(() => import('components/pages/admin/customer/Information'));
const AdminCustomerOverview = lazy(() => import('components/pages/admin/customer/Overview'));
const AdminCustomerActivities = lazy(() => import('components/pages/admin/customer/Activities'));
const AdminCustomerPlan = lazy(() => import('components/pages/admin/customer/Plan'));
const AdminCustomerUsers = lazy(() => import('components/pages/admin/customer/Users'));
const AdminCustomerUserDetail = lazy(() => import('components/pages/admin/customer/UserDetail'));
const AdminCustomerConnections = lazy(() => import('components/pages/admin/customer/Connections'));
const AdminCustomerConnectors = lazy(() => import('components/pages/admin/customer/Connectors'));
const AdminUsers = lazy(() => import('components/pages/admin/users/Users'));
const AdminSettings = lazy(() => import('components/pages/admin/settings/Settings'));
const AdminSettingsOverview = lazy(() => import('components/pages/admin/settings/Overview'));
const AdminSettingsAdmins = lazy(() => import('components/pages/admin/settings/Admins'));
const AdminSettingsRoles = lazy(() => import('components/pages/admin/settings/Roles'));
const AdminSettingsRights = lazy(() => import('components/pages/admin/settings/Rights'));
const AdminSettingsPlans = lazy(() => import('components/pages/admin/settings/Plans'));
const AdminCashouts = lazy(() => import('components/pages/admin/cashouts'));
const AdminPossibilityOverview = lazy(() => import('components/pages/admin/possibility/PossibilityOverview'));
const AdminTimeRegistrationsOverview = lazy(() => import('components/pages/admin/timeRegistration/Overview'));
const AdminUserRequests = lazy(() => import('components/pages/admin/request'));
const AdminResellers = lazy(() => import('components/pages/admin/reseller'));

//Customer pages
const CustomerDashboard = lazy(() => import('components/pages/customer/dashboard'));
const CustomerPossibilityOverview = lazy(() => import('components/pages/customer/possibility/PossibilityOverview'));
const CustomerCashoutOverview = lazy(() => import('components/pages/customer/cashout/CashoutOverview'));
const CustomerFinancialDetail = lazy(() => import('components/pages/customer/financial/Detail'));
const CustomerFinancialTransactions = lazy(() => import('components/pages/customer/financial/Transactions'));
const CustomerUsers = lazy(() => import('components/pages/customer/users/Users'));
const CustomerUserDetails = lazy(() => import('components/pages/customer/users/Detail'));
const CustomerUserOverview = lazy(() => import('components/pages/customer/users/Overview'));
const CustomerUserInformation = lazy(() => import('components/pages/customer/users/Information'));
const CustomerUserCashouts = lazy(() => import('components/pages/customer/users/Cashouts'));
const CustomerUserBalance = lazy(() => import('components/pages/customer/users/Balance'));
const CustomerUserPayPeriods = lazy(() => import('components/pages/customer/users/PayPeriods'));
const CustomerUserActivities = lazy(() => import('components/pages/customer/users/Activities'));
const CustomerUserRights = lazy(() => import('components/pages/customer/users/Rights'));
const CustomerUserTransactions = lazy(() => import('components/pages/customer/users/Transactions'));
const CustomerSettingsDetail = lazy(() => import('components/pages/customer/settings/Detail'));
const CustomerSettingsInformation = lazy(() => import('components/pages/customer/settings/Information'));
const CustomerSettingsProfile = lazy(() => import('components/pages/customer/settings/Profile'));
const CustomerSettingsRoles = lazy(() => import('components/pages/customer/settings/Roles'));
const CustomerSettingsRoleEdit = lazy(() => import('components/pages/customer/settings/RoleEdit'));
const CustomerSettingsConnections = lazy(() => import('components/pages/customer/settings/Connections'));
const CustomerSettingsPayPeriods = lazy(() => import('components/pages/customer/settings/PayPeriods'));
const CustomerTimeRegistrationsOverview = lazy(() => import('components/pages/customer/timeRegistration/Overview'));
const CustomerTimeRegistrationConfirmation = lazy(() => import('components/pages/account/TimeRegistrationConfirmation'));
const CustomerUserRequests = lazy(() => import('components/pages/customer/request'));

//OAuth
const OAuthAuthorize = lazy(() => import('components/pages/oauth/OAuthAuthorize'));

const AppRoutes = () => {
    return (
        <Suspense fallback={<Loading full />}>
            <Routes>
                <Route index element={<Default />} />
                <Route path="account" element={<Account />}>
                    <Route path="login" element={<Login />} />
                    {
                        process.env.NODE_ENV === 'development' &&
                        <Route path="loginwithusername/:username" element={<LoginWithUserName />} />
                    }
                    <Route path="register/admin/:code" element={<Register />} />
                    <Route path="register/customer-user/:code" element={<RegisterCustomerUser />} />
                    <Route path="forgot-password" element={<ForgotPassword />} />
                    <Route path="invite" element={<Invite />} />
                    <Route path="reset-password/:code/:username" element={<ResetPassword />} />
                    <Route path="verify/modificationRequest/:code" element={<ConfirmModificationRequest />} />
                    <Route path="tfa" element={<Tfa />} />
                    <Route path="app" element={<AppInformation />} />
                    <Route path="time-registration-confirm/:code" element={<CustomerTimeRegistrationConfirmation />} />
                    <Route path="cashout-confirm/:code" element={<ConfirmCashOut />} />
                </Route>
                <Route path="admin" element={<Admin />}>
                    <Route path="dashboard" element={<AdminDashboard />} />
                    <Route path="customers" element={<Outlet />}>
                        <Route index element={<AdminCustomerList />} />
                        <Route path=":customerId" element={<AdminCustomerDetail />}>
                            <Route index element={<Navigate replace to="overview" />} />
                            <Route path="overview" element={<AdminCustomerOverview />} />
                            <Route path="information" element={<AdminCustomerInformation />} />
                            <Route path="financial" element={<AdminCustomerFinancial />} />
                            <Route path="invoices" element={<AdminCustomerInvoices />} />
                            <Route path="activities" element={<AdminCustomerActivities />} />
                            <Route path="plan" element={<AdminCustomerPlan />} />
                            <Route path="users" element={<Outlet />}>
                                <Route index element={<AdminCustomerUsers />} />
                                <Route path=":customerUserId" element={<AdminCustomerUserDetail />} />
                            </Route>
                            <Route path="connections" element={<AdminCustomerConnections />} />
                            <Route path="connectors" element={<AdminCustomerConnectors />} />
                        </Route>
                    </Route>
                    <Route path="users" element={<Outlet />}>
                        <Route index element={<AdminUsers />} />
                    </Route>
                    <Route path="resellers" element={<Outlet />}>
                        <Route index element={<AdminResellers />} />
                        <Route path=":customerId" element={<AdminCustomerDetail />}>
                            <Route index element={<Navigate replace to="overview" />} />
                            <Route path="overview" element={<AdminCustomerOverview />} />
                        </Route>
                    </Route>
                    <Route path="cashouts" element={<AdminCashouts />} />
                    <Route path="possibilities" element={<AdminPossibilityOverview />} />
                    <Route path="request" element={<AdminUserRequests />} />
                    <Route path="time-registrations" element={<AdminTimeRegistrationsOverview />} />
                    <Route path="financial" element={<></>} />
                    <Route path="settings" element={<AdminSettings />}>
                        <Route index element={<Navigate replace to="overview" />} />
                        <Route path="overview" element={<AdminSettingsOverview />} />
                        <Route path="admins" element={<AdminSettingsAdmins />} />
                        <Route path="roles" element={<AdminSettingsRoles />} />
                        <Route path="rights" element={<AdminSettingsRights />} />
                        <Route path="plans" element={<AdminSettingsPlans />} />
                    </Route>
                </Route>

                <Route path="customer" element={<Customer />}>
                    <Route index element={<Navigate replace to="dashboard" />} />
                    <Route path="dashboard" element={<CustomerDashboard />} />
                    <Route path="cashouts" element={<CustomerCashoutOverview />} />
                    <Route path="possibilities" element={<CustomerPossibilityOverview />} />
                    <Route path="financial" element={<CustomerFinancialDetail />}>
                        <Route index element={<Navigate replace to="transactions" />} />
                        <Route path="transactions" element={<CustomerFinancialTransactions />} />
                        <Route path="invoices" element={<></>} />
                    </Route>
                    <Route path="request" element={<CustomerUserRequests />} />
                    <Route path="users" element={<Outlet />}>
                        <Route index element={<CustomerUsers />} />
                        <Route path=":customerUserId" element={<CustomerUserDetails />}>
                            <Route index element={<Navigate replace to="overview" />} />
                            <Route path="overview" element={<CustomerUserOverview />} />
                            <Route path="information" element={<CustomerUserInformation />} />
                            <Route path="cashouts" element={<CustomerUserCashouts />} />
                            <Route path="balance" element={<CustomerUserBalance />} />
                            <Route path="activities" element={<CustomerUserActivities />} />
                            <Route path="payperiods" element={<CustomerUserPayPeriods />} />
                            <Route path="rights" element={<CustomerUserRights />} />
                            <Route path="transactions" element={<CustomerUserTransactions />} />
                        </Route>
                    </Route>
                    <Route path="time-registrations" element={<CustomerTimeRegistrationsOverview />} />
                    <Route path="settings" element={<CustomerSettingsDetail />}>
                        <Route index element={<Navigate replace to="information" />} />
                        <Route path="profile" element={<CustomerSettingsProfile />} />
                        <Route path="information" element={<CustomerSettingsInformation />} />
                        <Route path="roles" element={<CustomerSettingsRoles />} />
                        <Route path="roles/:roleId" element={<CustomerSettingsRoleEdit />} />
                        <Route path="connections" element={<CustomerSettingsConnections />} />
                        <Route path="payperiods" element={<CustomerSettingsPayPeriods />} />
                    </Route>
                </Route>
                <Route path="oauth" element={<Account />}>
                    <Route path="authorize" element={<OAuthAuthorize />} />
                </Route>
            </Routes>
        </Suspense>
    );
};

export default AppRoutes;
