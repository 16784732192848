import { PropsWithChildren } from 'react';
import { createContext, FC, useContext, useState } from 'react';

// Context.
interface MenuContextValue {
    isOpen: boolean;
    setIsOpen: (open: boolean) => void;
}

const MenuContext = createContext<MenuContextValue>({
    isOpen: true,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    setIsOpen: () => { }
});

export const useMenuContext = () => {
    const context = useContext(MenuContext);

    if (!context) {
        throw new Error('useMenu must be used within a MenuProvider');
    }

    return context;
};

// Provider.
export const MenuProvider: FC<PropsWithChildren> = ({ children }) => {
    const [isOpen, setIsOpen] = useState<boolean>(true);

    // Render.
    return <MenuContext.Provider value={{ isOpen, setIsOpen }}>{children}</MenuContext.Provider>;
};
