import { transparentize } from 'polished';
import greys from './greys';

const colors = {
    primary: '#12A05E',
    primaryLight: '#C9F7E1',
    primaryDark: '#00AA64',
    secondary: '#703CE5',
    secondaryLight: '#ECE3FC',
    secondaryDark: '#612ED5',
    tertiary: '#E2752E',
    tertiaryLight: transparentize(0.2, '#E2752E'),
    tertiaryDark: '#E2752E',
    white: '#ffffff',
    black: '#000000',
    grey: greys[300],
    greyLight: greys[25],
    greyDark: greys[700],
    yellow: '#E0A93F',
    yellowLight: transparentize(0.35, '#F4B743'),
    purple: '#D59FB2',
    purpleLight: transparentize(0.35, '#F0B2C8'),
    green: '#83B593',
    greenLight: transparentize(0.35, '#A0CFAF'),
    mediumGrey: greys[300],
    dark: greys[700],
    veryDark: '#1A1C1E',
    selectedDark: '#282B2E',
    blue: '#6A79F9',
    blueLight: '#E9EBFE',
    border: '#E0DFDF',
    textLight: '#A3A4A5',
    text: greys[900],
    textDark: '#0F282F',
    warning: '#FFE4C0',
    warningText: '#E2752E',
    error: '#FEE1E1',
    errorText: '#EC5B56',
    success: '#93EFC4',
    successText: '#12A05E',
    info: '#D9C8FA',
    infoText: '#703CE5',
    background: '#F8F8F8',
    header: '#00372D'
};

export type Colors = typeof colors;

export default colors;
