export enum Claim {
    UserId = 'api.cashout.nl.userId',
    UserName = 'api.cashout.nl.userName',
    Roles = 'api.cashout.nl.role',
    Rights = 'api.cashout.nl.right',
    CustomerRights = 'api.cashout.nl.customerRight',
    ActiveCustomerId = 'api.cashout.nl.activeCustomerId',
    ActiveCustomerUserId = 'api.cashout.nl.activeCustomerUserId',
    UserType = 'api.cashout.nl.userType'
}
