export interface Header {
    big: number;
    small: number;
}

const header: Header = {
    big: 184,
    small: 72
};

export default header;
