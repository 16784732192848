export interface Navigation {
    big: number;
    small: number;
}

const navigation: Navigation = {
    big: 246,
    small: 60
};

export default navigation;
